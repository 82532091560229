// import node modules
import React, { Component } from "react";
import { Link } from "gatsby";
import { TermsOfUse } from "@tyson-foods/react-component-library";
import { Container } from "reactstrap";

// import components
import SEO from "../components/seo";
import Layout from "../components/Layout/Layout.component";

class Products extends Component {
  render() {
    return (
      <Layout
        metaTitle="Terms of Use | Galileo® Salame" 
        metaDescription="Information on terms of use for visitors to our site."
      >
        <section>
        <Container
          className="gagl-terms-container gagl-nav-top-space"          
        >
          <TermsOfUse
            className="px-4 px-xl-5 gagl-legal-pages"
            privacyPolicyLink={
              <Link to="/privacy-policy/">PRIVACY POLICY</Link>
            }
            website="https://www.tyson.com/"
          />
        </Container>
        </section>
      </Layout>
    );
  }
}

export default Products;
